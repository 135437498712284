import { DeploymentEnvironmentName } from "@src/appV2/environment";

export interface FirebaseConfig {
  apiKey: string;
  authDomain: string;
  databaseURL: string;
  projectId: string;
  storageBucket: string;
  messagingSenderId: string;
  appId: string;
  measurementId: string;
  dynamicShortLinkApiUrl: string;
}

const firebaseConfigDevelopment = {
  apiKey: "AIzaSyC8W_0rbJCPhUHl0pf0XkWC59R4eHGpSmA",
  authDomain: "clipboard-app.firebaseapp.com",
  databaseURL: "https://clipboard-app.firebaseio.com",
  projectId: "clipboard-app",
  storageBucket: "clipboard-app.appspot.com",
  messagingSenderId: "651599910014",
  appId: "1:651599910014:web:1690f2328ca70ced14342f",
  measurementId: "G-EFPXTPH5QT",
  dynamicShortLinkApiUrl: "https://firebasedynamiclinks.googleapis.com/v1/shortLinks",
};

const firebaseConfigProdRecreated = {
  apiKey: "AIzaSyCu7r4wMXDSWUfzwNbhnG7DCtDda6lfzBA",
  authDomain: "clipboard-recreated.firebaseapp.com",
  databaseURL: "https://clipboard-recreated.firebaseio.com",
  projectId: "clipboard-recreated",
  storageBucket: "clipboard-recreated.appspot.com",
  messagingSenderId: "651599910014",
  appId: "1:651599910014:web:1690f2328ca70ced14342f",
  measurementId: "G-EFPXTPH5QT",
  dynamicShortLinkApiUrl: "https://firebasedynamiclinks.googleapis.com/v1/shortLinks",
};

const firebaseConfigProduction = {
  apiKey: "AIzaSyA3PoA1BjIP1TGD4GiHZsBSOMqHeXE5HYE",
  authDomain: "clipboard-health.firebaseapp.com",
  databaseURL: "https://clipboard-health.firebaseio.com",
  projectId: "clipboard-health",
  storageBucket: "",
  messagingSenderId: "1098403931480",
  appId: "1:1098403931480:web:cf36f44ee505ab81",
  measurementId: "G-K65B9132JS",
  dynamicShortLinkApiUrl: "https://firebasedynamiclinks.googleapis.com/v1/shortLinks",
};

export function getFirebaseConfig(environmentName: DeploymentEnvironmentName): FirebaseConfig {
  switch (environmentName) {
    case DeploymentEnvironmentName.LOCAL: {
      return firebaseConfigDevelopment;
    }

    case DeploymentEnvironmentName.DEVELOPMENT:
    case DeploymentEnvironmentName.STAGING:
    case DeploymentEnvironmentName.PROD_SHADOW: {
      return firebaseConfigDevelopment;
    }

    case DeploymentEnvironmentName.PROD_RECREATED: {
      return firebaseConfigProdRecreated;
    }

    case DeploymentEnvironmentName.PRODUCTION: {
      return firebaseConfigProduction;
    }

    default: {
      return firebaseConfigProduction;
    }
  }
}

export const firebaseConfig = getFirebaseConfig(
  process.env.REACT_APP_ENVIRONMENT_NAME as DeploymentEnvironmentName
);
